import { useEffect } from "react";
import { connect } from "react-redux";
import CityForm from "./CityForm";
import MsgForm from "./MsgForm";
import ClientForm from "./ClientForm";
import FilialForm from "./FilialForm";
import RegionForm from "./RegionForm";

import "./Forms.css";
import ExcelReader from "./Excel/ExcelReader";

const MahsulotBolimForm = ({ onChange, modal, datas, values, path }) => {
  let form = "";

  switch (path) {
    case "/":
      form = <FilialForm onChange={onChange} modal={modal} values={values} />;
      break;
    case "/oblast":
      form = <CityForm onChange={onChange} modal={modal} values={values} />;
      break;
    case "/gorod":
      form = <RegionForm onChange={onChange} modal={modal} datas={datas} values={values} />;
      break;
    case "/MSG":
      form = <MsgForm onChange={onChange} modal={modal} datas={datas} values={values} />;
      break;
    case "/klientlar":
      form = <ClientForm onChange={onChange} modal={modal} datas={datas} values={values} />;
      break;

    default:
      break;
  }

  return (
    <>
      {form}
      {/* <CityForm onChange={onChange} modal={modal} values={values} />
      <RegionForm onChange={onChange} modal={modal} datas={datas} values={values} />
      <MsgForm onChange={onChange} modal={modal} datas={datas} values={values} />
      <ClientForm onChange={onChange} modal={modal} datas={datas} values={values} /> */}
      {modal.title === "Excel" && <ExcelReader />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    datas: state.meros.allData,
  };
};
export default connect(mapStateToProps)(MahsulotBolimForm);
