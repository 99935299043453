import {useEffect, useState} from "react";
import {connect} from "react-redux";
import Actions from "./Actions";
import Header from "./Header";
import MainTable from "./Table/MainTable";
import ModalExampleModal from "./Modal/Modal";
import {Spin} from "antd";

const Body = ({columns, rowS, datas, loading, nomi, path}) => {
	const [searchDataSource, setSearchDataSource] = useState([]);
	useEffect(() => {
		setSearchDataSource(datas);
	}, [datas]);
	return (
		<Spin tip="Loading..." spinning={loading}>
			<Header columns={columns} setSearchDataSource={setSearchDataSource}/>
			<div className="Body">
				<Actions/>
				<MainTable columns={columns} data={searchDataSource} rowS={rowS}/>
				<ModalExampleModal nomi={nomi} path={path}/>
			</div>
		</Spin>
	);
};

const mapStateToProps = state => {
	return {
		datas: state.meros.mainTableData,
		loading: state.meros.loading,
	};
};

export default connect(mapStateToProps)(Body);
