import {Button, message, Popconfirm} from "antd";
import {RiEdit2Line} from "react-icons/ri";
import {getMainTableData, setModal, setRowId, setValues} from "../Actions/MerosActions";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {GoPlus} from "react-icons/go";
import {CgClose} from "react-icons/cg";
import {useHistory} from "react-router";
import FetchApi from "../api/FetchApi";

const Actions = ({setModal, rowId, mainTableData, getMainTableData, setValues, setRowId, values}) => {
	const {location} = useHistory();
	const [title, setTitle] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		switch (location.pathname) {
			case "/":
				setTitle("Филиалы");
				break;
			case "/oblast":
				setTitle("Область");
				break;
			case "/gorod":
				setTitle("Город / Район");
				break;
			case "/MSG":
				setTitle("МСГ");
				break;
			case "/klientlar":
				setTitle("Регистрация");
				break;
			case "/hisobot":
				setTitle("");
				break;

			default:
				break;
		}
	}, []);

	const openAdd = () => {
		setModal({
			title: title,
			open: true,
			status: "add",
		});
		setValues({});
		setRowId(null);
	};
	const openEdit = () => {
		if (rowId) {
			let item = mainTableData.find((i) => i.id === rowId);
			values === {} && setValues(item);
			setModal({
				title: title,
				open: true,
				status: "edit",
			});
		} else {
			message.warning("Qatorni tanlang!");
		}
	};
	const handleDelete = async () => {
		if (rowId) {
			setLoading(true);
			await FetchApi(location.pathname + "/" + rowId, "DELETE");
			getMainTableData(location.pathname);
			setLoading(false);
		} else {
			message.warning("Qatorni tanlang!");
		}
	};

	return (
		<div style={{display: "flex", marginBottom: "2px", marginTop: "-7px"}}>
			<Button onClick={openAdd} type="primary" className="action_btn">
				<GoPlus/> &nbsp; Қўшиш
			</Button>
			{JSON.parse(window.localStorage.getItem("mаlinaUser")).type == 1 ? (
				<>
					<Button onClick={openEdit} type="primary" className="action_btn">
						<RiEdit2Line/> &nbsp; Ўзгартириш
					</Button>

					<Popconfirm placement="top"
								title="Маълумот ўчирилади"
								onConfirm={handleDelete}
								okText="Ха"
								cancelText="Бекор қилиш">
						<Button loading={loading} className="action_btn_red">
							<CgClose/> &nbsp; Ўчириш
						</Button>
					</Popconfirm>
				</>
			) : ""
			}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		modal: state.meros.modal,
		rowId: state.meros.rowId,
		mainTableData: state.meros.mainTableData,
		values: state.meros.values,
	};
};
export default connect(mapStateToProps, {setModal, setValues, getMainTableData, setRowId})(Actions);
