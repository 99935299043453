import React from "react";
import brand from "../../icons/brand2.png";
const SideHeader = () => {
  return (
    <div className="sideHeader">
      <div className="brand">
        <img src={brand} className={"logo"} alt="brand logo" />
      </div>
    </div>
  );
};

export default SideHeader;
