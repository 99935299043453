import { useState } from "react";
import "../App.css";
import SideBar from "../components/Sidebar/Sidebar";
import { HiLocationMarker } from "react-icons/hi";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import TopicMenu from "../components/Sidebar/TopicMenu";
import { Layout } from "antd";
import {
  RiBuilding2Fill,
  RiBuildingLine,
  RiFlag2Fill,
  RiMailFill,
  RiUser2Fill,
} from "react-icons/ri";
import { AiFillPieChart } from "react-icons/ai";
import Filiallar from "../components/Pages/Filiallar";
import Regions from "../components/Pages/Regions";
import Cities from "../components/Pages/Cities";
import MSG from "../components/Pages/MSG";
import Clients from "../components/Pages/Clients";
import Hisobot from "../components/Pages/Hisobot";
import Sms from "../components/Pages/Sms";
import HisobotFiallar from "./Pages/HisobotFiliallar";
import HisobotHodimlar from "./Pages/HisobotHodimlar";

function App({ location }) {
  const topics = [
    {
      title: "Филиаллар",
      path: "/",
      icon: <RiFlag2Fill />,
      comp: <Filiallar path="/" nomi={"Филиаллар"} />,
    },
    {
      title: "Вилоятлар",
      path: "/oblast",
      icon: <HiLocationMarker />,
      comp: <Regions path="/oblast" nomi={"Вилоятлар"} />,
    },
    {
      title: "Шахарлар",
      path: "/gorod",
      icon: <RiBuilding2Fill />,
      comp: <Cities path="/gorod" nomi={"Шахарлар"} />,
    },
    {
      title: "МФЙ",
      path: "/MSG",
      icon: <RiBuildingLine />,
      comp: <MSG path="/MSG" nomi={"МФЙ"} />,
    },
    {
      title: "Мижозлар",
      path: "/klientlar",
      icon: <RiUser2Fill />,
      comp: <Clients path="/klientlar" nomi={"Мижознинг савдо тарихи"} />,
    },
    {
      title: "Хисоботлар",
      path: "/hisobot",
      icon: <AiFillPieChart />,
      comp: <Hisobot path="/hisobot" nomi={"Хисоботлар"} />,
    },
    {
      title: "Хисобот филиаллар",
      path: "/hisobotFiliallar",
      icon: <AiFillPieChart />,
      comp: (
        <HisobotFiallar path="/hisobotFiliallar" nomi={"Хисобот филиаллар"} />
      ),
    },
    {
      title: "Хисобот ходимлар",
      path: "/hisobotHodimlar",
      icon: <AiFillPieChart />,
      comp: (
        <HisobotHodimlar path="/hisobotHodimlar" nomi={"Хисобот ходимлар"} />
      ),
    },
    {
      title: "СМС",
      path: "/SMS",
      icon: <RiMailFill />,
      comp: <Sms path="/SMS" nomi={"СМС"} />,
    },
  ];
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
  };

  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  const malinaUser = JSON.parse(window.localStorage.getItem("mаlinaUser"));

  return (
    <Layout>
      <SideBar menu={Menu} />
      <Layout.Content>
        <Switch>
          {topics.map((top, index) => (
            <Route key={index} exact path={top.path}>
              {top.comp}
            </Route>
          ))}
        </Switch>
        {malinaUser.type == 2 ? <Redirect exact to={"/klientlar"} /> : null}
      </Layout.Content>
    </Layout>
  );
}

export default withRouter(App);
