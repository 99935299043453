import { connect } from "react-redux";
import { useEffect } from "react";
import {
  getMainTableData,
  getAllData,
  setRowId,
} from "../../Actions/MerosActions";
import Body from "../Body";
import _ from "lodash";

const MSG = ({
  getMainTableData,
  getAllData,
  mainTableData,
  allData,
  setRowId,
  path,
  nomi,
}) => {
  useEffect(() => {
    getMainTableData(path);
    getAllData();
    setRowId(null);
  }, []);
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "tartib",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">МФЙ номи</div>,
      dataIndex: "nomi",
      width: "232px",
      key: "nomi",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Тартиб</div>,
      dataIndex: "tartib",
      key: "tartib",
      width: "100px",
      align: "center",
    },
    {
      title: <div className="table_head">Шахар / Туман</div>,
      dataIndex: "shahar_id",
      width: "232px",
      key: "gorod",
      filters: _.uniqBy(mainTableData, (e) => e.shahar_id).map((d) => {
        return {
          text: allData.gorod?.find((t) => t.Id == d.shahar_id)
            ? allData.gorod?.find((t) => t.Id == d.shahar_id).nomi
            : null,
          value: d.shahar_id,
        };
      }),
      onFilter: (value, record) => record.shahar_id == value,
      padding: "1rem",
      render: (text) => {
        if (allData.gorod && allData.gorod.find((g) => g.Id == text)) {
          return allData.gorod.find((g) => g.Id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyat_id",
      width: "951px",
      key: "oblast",
      filters: _.uniqBy(mainTableData, (e) => e.viloyat_id).map((d) => {
        return {
          text: allData.oblast?.find((t) => t.Id == d.viloyat_id)
            ? allData.oblast?.find((t) => t.Id == d.viloyat_id).nomi
            : null,
          value: Number(d.viloyat_id),
        };
      }),
      onFilter: (value, record) => record.viloyat_id.indexOf(value) === 0,
      padding: "1rem",
      render: (text) => {
        if (allData.oblast && allData.oblast.find((o) => o.Id == text)) {
          return allData.oblast.find((o) => o.Id == text).nomi;
        } else return null;
      },
    },
  ];

  return <Body columns={columns} nomi={nomi} path={path} />;
};
const mapStateToProps = (state) => {
  return {
    allData: state.meros.allData,
    mainTableData: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, {
  getMainTableData,
  getAllData,
  setRowId,
})(MSG);
