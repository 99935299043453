import {useEffect} from "react";
import {connect} from "react-redux";
import {getMainTableData, setRowId} from "../../Actions/MerosActions";
import Body from "../Body";
import Zoom from "react-medium-image-zoom";
import {BaseUrl} from "../../BaseUrl";
import "react-medium-image-zoom/dist/styles.css";

const Filiallar = ({getMainTableData, setRowId, path, nomi}) => {
	useEffect(() => {
		getMainTableData(path);
		setRowId(null);
	}, []);

	const columns = [
		{
			title: <div className="table_head">№</div>,
			key: "index",
			render: (text, record, index) => index + 1,
			width: "50px",
			align: "center",
		},
		{
			title: <div className="table_head">Номи</div>,
			dataIndex: "nomi",
			width: "150px",
			key: "nomi",
			padding: "1rem",
		},
		{
			title: <div className="table_head">Мўлжал</div>,
			dataIndex: "second_name",
			width: "250px",
			key: "nomi2",
			padding: "1rem",
		},
		{
			title: <div className="table_head">Логин</div>,
			dataIndex: "login",
			width: "130px",
			key: "login",
			padding: "1rem",
		},
		{
			title: <div className="table_head">Парол</div>,
			dataIndex: "parol",
			width: "130px",
			key: "parol",
			padding: "1rem",
		},
		{
			title: <div className="table_head">Телефон</div>,
			dataIndex: "telefon",
			width: "150px",
			key: "telefon",
			padding: "1rem",
		},
		{
			title: <div className="table_head">Фото</div>,
			dataIndex: "img",
			key: "rasm",
			width: "100px",
			render: (text) => {
				if (text) {
					return (
						<Zoom zoomMargin={40}>
							<picture>
								<source
									media="max-width: 200px"
									srcSet={BaseUrl + text.slice(1)}
								/>
								<img src={BaseUrl + text.slice(1)} alt="asda" height={30}/>
							</picture>
						</Zoom>
					);
				} else return "";
			},
		},
	];

	return (
		<Body columns={columns} nomi={nomi} path={path}/>
	);
};

export default connect(null, {getMainTableData, setRowId})(Filiallar);
