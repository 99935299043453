import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    getMainTableData,
    setClients,
    setExcelData,
    setMainTableData,
    setModal,
    setRowId,
    setValues,
    setThead,
} from "../../Actions/MerosActions";
import Header from "../Header";
import MainTable from "../Table/MainTable";
import ModalExampleModal from "../Modal/Modal";
import {Button, Form, Input, message, Select} from "antd";
import {AiOutlineFile} from "react-icons/ai";
import {RiMailSendLine} from "react-icons/ri";
import "./Sms.css";
import {Option} from "antd/lib/mentions";
import FetchApi from "../../api/FetchApi";

const Sms = ({
                 datas,
                 setMainTableData,
                 setModal,
                 excelData,
                 getMainTableData,
                 setRowId,
                 setClients,
                 setExcelData,
                 setThead,
                 clients,
                 thead,
             }) => {
    const [msg, setMsg] = useState("");
    const [searchDataSource, setSearchDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getMainTableData("/sms");
        setRowId(null);
    }, []);

    useEffect(() => {
        setSearchDataSource(excelData.length > 0 ? excelData : datas);
    }, [datas, excelData]);

    const smsToClient = (v, id) => {
        const data = excelData.length > 0 ? excelData : datas;
        const mapedData = data.map((d) => {
            if (d.id == id) {
                return {...d, message: v.target.value};
            } else return d;
        });
        if (excelData.length > 0) {
            setExcelData(mapedData);
        } else {
            setMainTableData(mapedData);
        }
    };
    const onChange = (e) => {
        setMsg(e.target.value);
    };
    const sendMsg = async () => {
        const data = excelData.length > 0 ? excelData : datas;

        const mapedData = clients.map((c) => {
            const obj = data.find((d) => d.id == c);
            if (obj) {
                const th = thead ? thead : "telefon";
                return {telefon: obj[th], message: obj.message ? obj.message : msg};
            }
        });

        const actdata = mapedData.filter((d) => d.message != "");
        if (actdata.length > 0) {
            const res = await FetchApi("/sms", "POST", actdata);
            if (res && res.status == 200) {
                message.success("SMS xabarlar jo'natildi");
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
        } else {
            message.warning("SMS xabarni kiriting!");
        }
    };
    const columns = excelData.length > 0 ? Object.keys(excelData[0]).map((d) => {
            if (d === "key") {
                return {
                    title: <div className="table_head">Сообщение</div>,
                    dataIndex: "message",
                    width: "313px",
                    key: "message",
                    padding: "1rem",
                    render: (text, record, index) => {
                        return (
                            <Input
                                placeholder={"сообщение..."}
                                disabled={!clients.find((item) => item == record.id)}
                                value={text}
                                onChange={(v) => smsToClient(v, record.id)}
                            />
                        );
                    },
                };
            } else {
                return {
                    title: (
                        <Select
                            onChange={(d) => setThead(d)}
                            placeholder=". . ."
                            className={"table_select"}
                        >
                            {/* {Object.keys(excelData[0]).map((a) => (
                    <Option value={d}>{a}</Option>
                  ))} */}
                            <Option value={d}>telefon</Option>
                        </Select>
                    ),
                    dataIndex: d,
                    width: "262px",
                    align: "center",
                };
            }
        })
        : [
            {
                title: <div className="table_head">№</div>,
                key: "index",
                render: (text, record, index) => index + 1,
                width: "50px",
                align: "center",
            },
            {
                title: <div className="table_head">Исм фамилия</div>,
                dataIndex: "fullName",
                width: "262px",
                key: "fullName",
                padding: "1rem",
            },
            {
                title: <div className="table_head">Телефон рақам</div>,
                dataIndex: "telefon",
                width: "179px",
                key: "telefon",
                padding: "1rem",
            },
            {
                title: <div className="table_head">Вилоят</div>,
                dataIndex: "region",
                width: "129px",
                key: "region",
                padding: "1rem",
            },
            {
                title: <div className="table_head">Шахар / Туман</div>,
                dataIndex: "city",
                width: "162px",
                key: "city",
                padding: "1rem",
            },
            {
                title: <div className="table_head">МФЙ</div>,
                dataIndex: "msg",
                width: "151px",
                key: "msg",
                padding: "1rem",
            },
            {
                title: <div className="table_head">Манзил</div>,
                dataIndex: "address",
                width: "179px",
                key: "address",
                padding: "1rem",
            },
            {
                title: <div className="table_head">Сообщение</div>,
                dataIndex: "message",
                width: "313px",
                key: "message",
                padding: "1rem",
                render: (text, record, index) => {
                    return (
                        <Input
                            placeholder={"сообщение..."}
                            disabled={!clients.find((item) => item == record.id)}
                            value={text}
                            onChange={(v) => smsToClient(v, record.id)}
                        />
                    );
                },
            },
        ];
    return (
        <>
            <Header columns={columns} setSearchDataSource={setSearchDataSource}/>
            <div className="Body">
                <div className={"sms_wrapper"}>
                    <Form style={{display: "flex", alignItems: "center"}}>
                        <Button onClick={() => {
                            setModal({
                                title: "Excel",
                                open: true,
                                status: "edit",
                            });
                            setClients([]);
                        }}
                                className="sms_btn btn_excel">
                            <AiOutlineFile/> &nbsp; ЕXCEL импорт
                        </Button>
                        <Input onChange={onChange}
                               value={msg}
                               className={"section_name_input sms_input"}
                               placeholder={"Сообщение"}
                        />
                        <Button
                            loading={loading}
                            onClick={sendMsg}
                            className="sms_btn btn_send">
                            <RiMailSendLine/> &nbsp; Жўнатиш
                        </Button>
                    </Form>

                    <MainTable columns={columns}
                               data={searchDataSource}
                               message={msg}
                               setClient={setClients}
                               client={clients}/>
                    <ModalExampleModal/>
                </div>
            </div>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        datas: state.meros.mainTableData,
        values: state.meros.values,
        clients: state.meros.clients,
        excelData: state.meros.excelData,
        thead: state.meros.thead,
    };
};
export default connect(mapStateToProps, {
    getMainTableData,
    setModal,
    setMainTableData,
    setClients,
    setValues,
    setRowId,
    setExcelData,
    setThead,
})(Sms);
