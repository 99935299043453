import _ from "lodash";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getAllData,
  getMainTableData,
  setModal,
  setRowId,
} from "../../Actions/MerosActions";
import Body from "../Body";
import { CgSearchLoading } from "react-icons/all";

const Clients = ({
  getMainTableData,
  setModal,
  mainTableData,
  getAllData,
  allData,
  setRowId,
  path,
  nomi,
}) => {
  useEffect(() => {
    getMainTableData(path);
    getAllData();
    setRowId(null);
  }, []);
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => (
        <>
          <div className="row-number">{index + 1}</div>
          <button
            className="loupe-btn"
            onClick={() => {
              setModal({
                open: true,
                title: "Мижоз хисоботи",
                id: record.Id,
                type: "reester",
              });
            }}
          >
            <CgSearchLoading />
          </button>
        </>
      ),
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">ФИО</div>,
      dataIndex: "fio",
      width: "200px",
      key: "fio",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      width: "150px",
      key: "telefon",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Туғулган сана</div>,
      dataIndex: "birth",
      width: "150px",
      key: "birth",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyat_id",
      width: "131px",
      key: "oblast",
      padding: "1rem",
      filters: _.uniqBy(mainTableData, (e) => e.viloyat_id).map((d) => {
        return {
          text: allData.oblast?.find((t) => t.Id == d.viloyat_id)
            ? allData.oblast?.find((t) => t.Id == d.viloyat_id).nomi
            : null,
          value: d.viloyat_id,
        };
      }),
      onFilter: (value, record) => record.viloyat_id == value,
      render: (text) => {
        if (allData.oblast && allData.oblast.find((o) => o.Id == text)) {
          return allData.oblast.find((o) => o.Id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Шахар / Туман</div>,
      dataIndex: "shahar_id",
      width: "162px",
      key: "gorod",
      padding: "1rem",
      filters: _.uniqBy(mainTableData, (e) => e.shahar_id).map((d) => {
        return {
          text: allData.gorod?.find((t) => t.Id == d.shahar_id)
            ? allData.gorod?.find((t) => t.Id == d.shahar_id).nomi
            : null,
          value: d.shahar_id,
        };
      }),
      onFilter: (value, record) => record.shahar_id == value,
      render: (text) => {
        if (allData.gorod && allData.gorod.find((g) => g.Id == text)) {
          return allData.gorod.find((g) => g.Id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">МФЙ номи</div>,
      dataIndex: "mfy_id",
      width: "186px",
      key: "msg",
      padding: "1rem",
      filters: _.uniqBy(mainTableData, (e) => e.mfy_id).map((d) => {
        return {
          text: allData.msg?.find((t) => t.Id == d.mfy_id)
            ? allData.msg?.find((t) => t.Id == d.mfy_id).nomi
            : null,
          value: d.mfy_id,
        };
      }),
      onFilter: (value, record) => record.mfy_id == value,
      render: (text) => {
        if (allData.msg && allData.msg.find((g) => g.Id == text)) {
          return allData.msg.find((g) => g.Id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Манзил</div>,
      dataIndex: "manzil",
      width: "182px",
      key: "manzil",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Штрих код</div>,
      dataIndex: "shtrix",
      width: "202px",
      key: "shtrix",
      padding: "1rem",
    },
    JSON.parse(window.localStorage.getItem("mаlinaUser")).type == 1
      ? {
          title: <div className="table_head">Кешбек қолдиғи</div>,
          dataIndex: "cashback",
          width: "202px",
          key: "cashback",
          padding: "1rem",
        }
      : {},
    {
      title: <div className="table_head">Филиал</div>,
      dataIndex: "filial_id",
      width: "131px",
      key: "oblast",
      padding: "1rem",
      filters: _.uniqBy(mainTableData, (e) => e.filial_id).map((d) => {
        return {
          text: allData.filial?.find((t) => t.Id == d.filial_id)
            ? allData.filial?.find((t) => t.Id == d.filial_id).nomi
            : null,
          value: d.filial_id,
        };
      }),
      onFilter: (value, record) => record.filial == value,
      render: (text) => {
        if (allData.filial && allData.filial.find((o) => o.Id == text)) {
          return allData.filial.find((o) => o.Id == text).nomi;
        } else return null;
      },
    },
  ];

  return <Body columns={columns} nomi={nomi} path={path} />;
};
const mapStateToProps = (state) => {
  return {
    allData: state.meros.allData,
    mainTableData: state.meros.mainTableData,
  };
};
export default connect(mapStateToProps, {
  getMainTableData,
  getAllData,
  setRowId,
  setModal,
})(Clients);
