import { Form, Input } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import ymaps from "ymaps";
import { setCoords } from "../../../Actions/MerosActions";
import Avatar from "../../Avatar";
import PhoneInput from "react-phone-input-2";
import ImgUploader from "../../ImgUploader";

const FilialForm = ({ onChange, modal, values, setCoords }) => {
  useEffect(() => {
    ymaps
      .load(
        "https://api-maps.yandex.ru/2.1.75/?apikey=bf0d01f7-ecf8-4802-b136-f4c4b9f3cefc&lang=ru_RU&onload=onLoad"
      )
      .then((maps) => {
        var myPlacemark,
          myMap = new maps.Map(
            "map",
            {
              center: [
                values?.lat ? values.lat : 40.3734,
                values?.lng ? values.lng : 71.7978,
              ],
              zoom: 14,
            },
            {
              searchControlProvider: "yandex#search",
            }
          );

        // Listening for a click on the map
        myMap.events.add("click", function (e) {
          var coords = e.get("coords");
          // Moving the placemark if it was already created
          setCoords({
            lat: coords[0],
            lng: coords[1],
          });

          if (myPlacemark) {
            myPlacemark.geometry.setCoordinates(coords);
          }
          // Otherwise, creating it.
          else {
            myPlacemark = createPlacemark(coords);
            myMap.geoObjects.add(myPlacemark);
            // Listening for the dragging end event on the placemark.
            myPlacemark.events.add("dragend", function () {
              getAddress(myPlacemark.geometry.getCoordinates());
            });
          }

          getAddress(coords);
        });

        // Creating a placemark
        function createPlacemark(coords) {
          return new maps.Placemark(
            coords,
            {
              iconCaption: "searching...",
            },
            {
              preset: "islands#violetDotIconWithCaption",
            }
          );
        }

        // Determining the address by coordinates (reverse geocoding).
        function getAddress(coords) {
          myPlacemark.properties.set("iconCaption", "searching...");
          maps.geocode(coords).then(function (res) {
            var firstGeoObject = res.geoObjects.get(0);

            myPlacemark.properties.set({
              // Forming a string with the object's data.
              iconCaption: [
                // The name of the municipality or the higher territorial-administrative formation.
                firstGeoObject.getLocalities().length
                  ? firstGeoObject.getLocalities()
                  : firstGeoObject.getAdministrativeAreas(),
                // Getting the path to the toponym; if the method returns null, then requesting the name of the building.
                firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
              ]
                .filter(Boolean)
                .join(", "),
              // Specifying a string with the address of the object as the balloon content.
              balloonContent: firstGeoObject.getAddressLine(),
            });
          });
        }
      })
      .catch((error) => console.log("Failed to load Yandex Maps", error));
  }, []);

  return (
    <Form className="filial_form">
      <div className="form_group">
        <label>Номи</label>
        <Input
          className={"section_name_input"}
          onChange={onChange}
          value={values.nomi}
          name={"nomi"}
        />
      </div>
      <div className="form_group">
        <label>Мўлжал</label>
        <Input
          className={"section_name_input"}
          onChange={onChange}
          value={values.second_name}
          name={"second_name"}
        />
      </div>
      <div className="form_group">
        <label>Тартиб</label>
        <Input
          className={"section_name_input"}
          onChange={onChange}
          defaultValue="100"
          type="number"
          value={values.tartib}
          name={"tartib"}
        />
      </div>
      <div className="form_group">
        <label>Логин</label>
        <Input
          className={"section_name_input"}
          onChange={onChange}
          value={values.login}
          name={"login"}
        />
      </div>
      <div className="form_group">
        <label>Пароль</label>
        <Input.Password
          onChange={onChange}
          value={values.parol}
          name={"parol"}
        />
      </div>
      <div className="form_group">
        <label>Телефон</label>
        <PhoneInput
          country={"uz"}
          inputStyle={{
            width: "100%",
          }}
          specialLabel={false}
          disableDropdown={true}
          countryCodeEditable={false}
          value={values.telefon ? values.telefon : "+998"}
          areaCodes={{
            uz: ["+998"],
          }}
          masks={{ uz: "(..) ...-..-.." }}
          prefix="+"
          onChange={(phone) => {
            const e = {
              target: {
                name: "telefon",
                value: phone,
              },
            };
            onChange(e);
          }}
        />
      </div>
      <div className="form_group form_group_upload">
        <div className="form_group_images" style={{ width: "350px" }}>
          <Avatar onChange={onChange} user={values} />
          <div className="form_group form_group_uploads">
            <ImgUploader onChange={onChange} user={values} />
            <ImgUploader onChange={onChange} user={values} />
            <ImgUploader onChange={onChange} user={values} />
          </div>
        </div>
        <div className="form_group_map">
          {/*</div>*/}
          {/*<div className="form_group">*/}
          <label>Жойлашув</label>
          <div id="map" />
        </div>
      </div>
    </Form>
  );
};

export default connect(null, { setCoords })(FilialForm);
