import Modal from "antd/lib/modal/Modal";
import { connect } from "react-redux";
import ModalForm from "./ModalForm";
import "./Modal.css";
import { AiFillCloseSquare } from "react-icons/ai";
import { setModal, setRowId, setValues } from "../../Actions/MerosActions";
import MijozHisoboti from "./MIjozHisoboti/MijozHisoboti";

const ModalExampleModal = ({ modal, setValues, setModal, setRowId, nomi, path }) => {
    const clearModal = () => {
        setModal({
            open: false,
            title: null,
            status: null,
        });
        setValues({});
        setRowId(null);
    };

    return (
        <Modal centered
            title={
                <div className={"modal_title"}>{nomi}</div>
            }
            visible={modal.open}
            footer={null}
            width={"auto"}
            onCancel={clearModal}
            closeIcon={
                <AiFillCloseSquare style={{
                    fontSize: "25px",
                    color: "#FF4B4B",
                    verticalAlign: "middle",
                    width: "28px",
                }} />
            }>
            <div className="modal_content">
                {
                    modal.type && modal.type === "reester" ?
                        <MijozHisoboti/> :
                        <ModalForm clearModal={clearModal} path={path} />
                }
            </div>
        </Modal>
    );
};

const mapStateToProps = state => {
    return {
        modal: state.meros.modal,
    };
};

export default connect(mapStateToProps, { setValues, setModal, setRowId })(ModalExampleModal);
