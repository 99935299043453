import { connect } from "react-redux";
import { useEffect } from "react";
import { getAllData, getMainTableData, setRowId } from "../../Actions/MerosActions";
import Body from "../Body";

const Regions = ({ getMainTableData, allData, getAllData, setRowId, path, nomi }) => {
  useEffect(() => {
    getMainTableData(path);
    getAllData();
    setRowId(null);
  }, []);
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "tartib",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "nomi",
      width: "232px",
      key: "nomi",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Тартиб</div>,
      dataIndex: "tartib",
      width: "232px",
      key: "nomi",
      padding: "1rem",
    },
    {
      title: <div></div>,
    },
  ];

  return <Body columns={columns} nomi={nomi} path={path} />;
};

const mapStateToProps = state => {
  return {
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, { getMainTableData, getAllData, setRowId })(Regions);
