import {
  GET_ALLDATA,
  GET_HTML_HISOBOT,
  SET_CLIENTS,
  SET_COORDS,
  SET_EXCEL_DATA,
  SET_LOADING,
  SET_MAIN_TABLE_DATA,
  SET_MODAL,
  SET_ROW_ID,
  SET_THEAD,
  SET_VALUES,
} from "../Actions/MerosTypes";

const initialState = {
  mainTableData: [],
  modal: { open: false, title: "" },
  values: {},
  rowId: null,
  openSide: null,
  loading: false,
  clients: [],
  coords: [],
  allData: {
    oblast: [],
    gorod: [],
    msg: [],
    filial: [],
  },
  excelData: [],
  thead: null,
  htmlHisobot: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_TABLE_DATA:
      return {
        ...state,
        mainTableData: action.payload,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case GET_HTML_HISOBOT:
      return {
        ...state,
        htmlHisobot: action.payload,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_VALUES:
      return {
        ...state,
        values: action.payload,
      };
    case SET_COORDS:
      return {
        ...state,
        values: { ...action.payload, ...state.values },
      };
    case SET_ROW_ID:
      return {
        ...state,
        rowId: action.payload,
      };
    case GET_ALLDATA:
      return {
        ...state,
        allData: action.payload,
      };

    case SET_EXCEL_DATA:
      return {
        ...state,
        excelData: action.payload,
      };
    case SET_THEAD:
      return {
        ...state,
        thead: action.payload,
      };

    default:
      return state;
  }
};
