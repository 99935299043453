import Header from "../Header";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./Hisobot.css";
import {Button, DatePicker, Form, Radio, Select} from "antd";
import {RiFlag2Line} from "react-icons/ri";
import {HiOutlineCalendar} from "react-icons/hi";
import {BsArrowRepeat} from "react-icons/bs";
import {
	getMainTableData,
	setRowId,
	getAllData,
	getHtmlHisobot,
} from "../../Actions/MerosActions";
import {Option} from "antd/lib/mentions";

import moment from "moment";
import _ from "lodash";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import HtmlParser from "react-html-parser";

const Hisobot = ({
					 datas,
					 allData,
					 getAllData,
					 setRowId,
					 hisobot,
					 loading,
					 getHtmlHisobot,
				 }) => {
	const [searchDataSource, setSearchDataSource] = useState([]);
	const [filterButton, setFilterButton] = useState("0");
	const {RangePicker} = DatePicker;

	const [dates1, setDates1] = useState(moment(new Date()).format("DD.MM.YYYY"));
	const [dates2, setDates2] = useState(moment(new Date()).format("DD.MM.YYYY"));

	const [filter, setFilter] = useState({
		vil: null,
		shahar: null,
		msg: null,
		age: null,
		filial: null,
	});

	const [radioValue, setRadioValue] = useState(5);

	const onRadioChange = (e) => {
		console.log("radio checked", e.target.value);
		setRadioValue(e.target.value);
	};

	const radioButtons = (e) => {
		setFilter({vil: null, shahar: null, msg: null, age: null, filial: null});
		setFilterButton(e.target.value);
	};

	const getHisobot = () => {
		getHtmlHisobot("/hisobot", {
			sana1: dates1 && moment(dates1, "DD.MM.YYYY").format("YYYY-MM-DD"),
			sana2: dates2 && moment(dates2, "DD.MM.YYYY").format("YYYY-MM-DD"),
			radio: radioValue,
		});
	};

	const changeDate1 = (e, d) => {
		setDates1(d);
	};

	const changeDate2 = (e, d) => {
		setDates2(d);
	};
	return (
		<>
			<Header columns={[]} setSearchDataSource={setSearchDataSource}/>
			<div className="Body">
				<div className={"hisobot_wrapper"}>
					{/* <Radio.Group onChange={radioButtons} defaultValue="0">
            <Radio.Button value="0" className="radio_btn">
              <BsArrowRepeat /> &nbsp; Айланма Хисобот
            </Radio.Button>
            <Radio.Button value="1" className="radio_btn">
              <HiOutlineCalendar /> &nbsp; Ёш бўйича Хисобот
            </Radio.Button>
            <Radio.Button value="2" className="radio_btn">
              <RiFlag2Line /> &nbsp; Филиал бўйича Хисобот
            </Radio.Button>
            <Radio.Button value="3" className="radio_btn">
              <RiFlag2Line /> &nbsp; Ёш группалари бўйича
            </Radio.Button>
          </Radio.Group>
          <hr /> */}
					<Form style={{display: "flex", flexWrap: "wrap", alignItems: "center"}}>
						<div className="form-group"
							 style={{width: "260px", display: "flex", gap: "0 20px"}}>
							<DatePicker
								onChange={changeDate1}
								separator
								defaultValue={moment(new Date(), "DD.MM.YYYY")}
								format={"DD.MM.YYYY"}
								allowClear={false}
								suffixIcon
								placeholder={"Сана 1"}
								locale={locale}
							/>
							<DatePicker
								onChange={changeDate2}
								separator
								format={"DD.MM.YYYY"}
								defaultValue={moment(new Date(), "DD.MM.YYYY")}
								allowClear={false}
								suffixIcon
								placeholder={"Сана 2"}
								locale={locale}
							/>
						</div>
						{filterButton !== "2" ? (
							<>
								{/* <div className="form-group" style={{ width: "150px" }}>
                  <Select
                    value={filter.vil}
                    placeholder="Вилоят"
                    onChange={(v) =>
                      setFilter({ vil: v, shahar: null, msg: null, age: null })
                    }
                    defaultValue="Вилоят"
                  >
                    <Option value={null}>Барчаси</Option>
                    {allData.oblast.map((d) => (
                      <Option value={d.Id} key={d.Id}>
                        {d.nomi}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="form-group" style={{ width: "120px" }}>
                  <Select
                    value={filter.shahar}
                    onChange={(v) =>
                      setFilter({ ...filter, shahar: v, msg: null, age: null })
                    }
                    placeholder="Шахар / Туман танланг"
                  >
                    {filter.vil
                      ? allData.gorod
                          .filter((g) => g.viloyat_id == filter.vil)
                          .map((d) => (
                            <Option value={d.Id} key={d.Id}>
                              {d.nomi}
                            </Option>
                          ))
                      : null}
                  </Select>
                </div> */}
								<div
									className="form-group"
									style={{display: "flex", gap: "0 10px"}}
								>
									{/* <Select
                    value={filter.msg}
                    onChange={(v) => setFilter({ ...filter, msg: v })}
                    placeholder="МФЙ"
                  >
                    {filter.shahar
                      ? allData.msg
                          .filter((g) => g.shahar_id == filter.shahar)
                          .map((d) => (
                            <Option value={d.Id} key={d.Id}>
                              {d.nomi}
                            </Option>
                          ))
                      : null}
                  </Select> */}
									{
										<Radio.Group onChange={onRadioChange} value={radioValue}>
											<Radio value={1}>Вилоятгача</Radio>
											<Radio value={2}>Тумангача</Radio>
											<Radio value={3}>МФЙгача</Radio>
											<Radio value={4}>Мижозгача</Radio>
											<Radio value={5}>Транзакциягача</Radio>
										</Radio.Group>
									}
								</div>

								{filterButton === "1" && (
									<>
										<div className="form-group" style={{width: "120px"}}>
											<Select
												placeholder="Ёш"
												onChange={(v) => setFilter({...filter, age: v})}
												value={filter.age}
											>
												<Option value={0}>Барчаси</Option>
												{_.uniqBy(datas, function (e) {
													return e.age;
												}).map((d) => (
													<Option value={d.age}>{d.age} ёш</Option>
												))}
											</Select>
										</div>
									</>
								)}
							</>
						) : (
							<div className="form-group" style={{width: "150px"}}>
								<Select
									placeholder="Филиал"
									onChange={(v) => setFilter({...filter, filial: v})}
								>
									<Option value={0}>Барчаси</Option>
									{allData.filial.map((d) => (
										<Option value={d.Id}>{d.nomi}</Option>
									))}
								</Select>
							</div>
						)}
						<div className="form-group">
							<Button onClick={getHisobot} loading={loading} type="primary">
								Янгилаш
							</Button>
						</div>
					</Form>
					{/* <MainTable columns={columns} data={searchDataSource}></MainTable> */}
					<div
						style={{
							marginTop: "10px",
							overflowX: "auto",
							overflowY: "calc(100vh - 220px)",
							maxHeight: "calc(100vh - 220px)",
						}}
					>
						{hisobot && hisobot.html ? HtmlParser(hisobot.html) : ""}
					</div>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		datas: state.meros.mainTableData,
		hisobot: state.meros.htmlHisobot,
		allData: state.meros.allData,
		loading: state.meros.loading,
	};
};
export default connect(mapStateToProps, {
	getMainTableData,
	setRowId,
	getAllData,
	getHtmlHisobot,
})(Hisobot);
