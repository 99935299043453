import { connect } from "react-redux";
import { useEffect } from "react";
import {
  getAllData,
  getMainTableData,
  setRowId,
} from "../../Actions/MerosActions";
import Body from "../Body";

const Cities = ({
  getMainTableData,
  getAllData,
  allData,
  setRowId,
  path,
  nomi,
}) => {
  useEffect(() => {
    getMainTableData(path);
    getAllData();
    setRowId(null);
  }, []);
  const columns = [
    {
      title: <div className="table_head">№</div>,
      key: "tartib",
      render: (text, record, index) => index + 1,
      width: "50px",
    },
    {
      title: <div className="table_head">Шахар / Туман</div>,
      dataIndex: "nomi",
      width: "200px",
      key: "gorod",
      padding: "1rem",
    },
    {
      title: <div className="table_head">Вилоят</div>,
      dataIndex: "viloyat_id",
      width: "200px",
      key: "oblast",
      padding: "1rem",
      render: (text) => {
        if (allData.oblast && allData.oblast.find((g) => g.Id == text)) {
          return allData.oblast.find((g) => g.Id == text).nomi;
        } else return null;
      },
    },
    {
      title: <div className="table_head">Тартиб</div>,
      dataIndex: "tartib",
      key: "tartib",
    },
  ];

  return <Body columns={columns} nomi={nomi} path={path} />;
};

const mapStateToProps = (state) => {
  return {
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, {
  getMainTableData,
  getAllData,
  setRowId,
})(Cities);
