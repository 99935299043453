import Header from "../Header";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import "./Hisobot.css";
import { Button, DatePicker, Form, Radio, Select } from "antd";
import {
  getMainTableData,
  setRowId,
  getAllData,
  getHtmlHisobot,
  setValues,
} from "../../Actions/MerosActions";

import moment from "moment";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import HtmlParser from "react-html-parser";
import { Option } from "antd/es/mentions";

const HisobotFiallar = ({
  datas,
  allData,
  getAllData,
  setValues,
  values,
  setRowId,
  hisobot,
  loading,
  getHtmlHisobot,
}) => {
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [filterButton, setFilterButton] = useState("0");
  const { RangePicker } = DatePicker;

  useEffect(() => {
    getAllData();
  }, []);
  const [dates1, setDates1] = useState(moment(new Date()).format("DD.MM.YYYY"));
  const [dates2, setDates2] = useState(moment(new Date()).format("DD.MM.YYYY"));

  const [filter, setFilter] = useState({
    vil: null,
    shahar: null,
    msg: null,
    age: null,
    filial: null,
  });

  const [radioValue, setRadioValue] = useState(5);

  const onRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const radioButtons = (e) => {
    setFilter({ vil: null, shahar: null, msg: null, age: null, filial: null });
    setFilterButton(e.target.value);
  };

  const getHisobot = () => {
    getHtmlHisobot("/hisobotFiliallar", {
      sana1: dates1 && moment(dates1, "DD.MM.YYYY").format("YYYY-MM-DD"),
      sana2: dates2 && moment(dates2, "DD.MM.YYYY").format("YYYY-MM-DD"),
      filial_id: values.filial_id,
    });
  };

  const changeDate1 = (e, d) => {
    setDates1(d);
  };

  const changeDate2 = (e, d) => {
    setDates2(d);
  };

  const onChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Header columns={[]} setSearchDataSource={setSearchDataSource} />
      <div className="Body">
        <div className={"hisobot_wrapper"}>
          <Form
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <div
              className="form-group"
              style={{ width: "auto", display: "flex", gap: "0 20px" }}
            >
              <DatePicker
                onChange={changeDate1}
                separator
                defaultValue={moment(new Date(), "DD.MM.YYYY")}
                format={"DD.MM.YYYY"}
                allowClear={false}
                suffixIcon
                placeholder={"Сана 1"}
                locale={locale}
              />
              <DatePicker
                onChange={changeDate2}
                separator
                format={"DD.MM.YYYY"}
                defaultValue={moment(new Date(), "DD.MM.YYYY")}
                allowClear={false}
                suffixIcon
                placeholder={"Сана 2"}
                locale={locale}
              />
              <div>
                <label className="hisobot-select__label">Филиал</label>
                <Select
                  onChange={(value) => {
                    const v = {
                      target: {
                        name: "filial_id",
                        value: value,
                      },
                    };
                    onChange(v);
                  }}
                  placeholder="Филиални танланг"
                  allowClear={true}
                  className="hisobot-select"
                  defaultValue={values.filial_id}
                >
                  {allData?.filial?.map((d) => (
                    <Option key={d.Id}>{d.nomi}</Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="form-group">
              <Button onClick={getHisobot} loading={loading} type="primary">
                Янгилаш
              </Button>
            </div>
          </Form>
          {/* <MainTable columns={columns} data={searchDataSource}></MainTable> */}
          <div
            style={{
              marginTop: "10px",
              overflowX: "auto",
              overflowY: "calc(100vh - 220px)",
              maxHeight: "calc(100vh - 220px)",
            }}
          >
            {hisobot && hisobot.html ? HtmlParser(hisobot.html) : ""}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    datas: state.meros.mainTableData,
    hisobot: state.meros.htmlHisobot,
    allData: state.meros.allData,
    loading: state.meros.loading,
    values: state.meros.values,
  };
};
export default connect(mapStateToProps, {
  getMainTableData,
  setRowId,
  getAllData,
  setValues,
  getHtmlHisobot,
})(HisobotFiallar);
