import {Table} from "ant-table-extensions";
import {connect} from "react-redux";
import {setClients, setRowId, setValues} from "../../Actions/MerosActions";
import {useHistory} from "react-router";
import "./Table.css";
import {Empty} from "antd";

const MainTable = ({
					   columns,
					   data,
					   setRowId,
					   clients,
					   rowId,
					   message,
					   setValues,
					   setClients,
					   thead,
				   }) => {
	const {location} = useHistory();

	const onRowClicked = (item) => {
		return {
			onClick: () => {
				setRowId(item.Id);
				setValues(item);
			},
		};
	};

	const setRowClassName = (record) => {
		if (location.pathname !== "/SMS" && location.pathname !== "/hisobot") {
				return record.Id === rowId ? "clickRowStyl" : "";
		}
		return "red-row";
	};

	const rowSelection = {
		onChange: (keys, rows) => {
			setClients(keys);
		},
		selectedRowKeys: clients,
	};

	let locale = {
		emptyText: (
			<Empty
				description={"Маълумот мавжуд эмас"}
				style={{margin: "2rem 0"}}
			/>
		),
	};

	return (
		<Table locale={locale}
			   size="small"
			   pagination={false}
			   rowClassName={setRowClassName}
			   dataSource={data}
			   columns={columns}
			   scroll={{y: 450}}
			   onRow={onRowClicked}
			   rowSelection={location.pathname === "/SMS" && rowSelection}
		/>
	);
};

const mapStateToProps = (state) => {
	return {
		rowId: state.meros.rowId,
		clients: state.meros.clients,
		thead: state.meros.thead,
	};
};

export default connect(mapStateToProps, {setRowId, setClients, setValues})(
	MainTable
);
