import { message } from "antd";
import FetchApi from "../api/FetchApi";
import {
  GET_ALLDATA,
  GET_HTML_HISOBOT,
  SET_CLIENTS,
  SET_COORDS,
  SET_EXCEL_DATA,
  SET_LOADING,
  SET_MAIN_TABLE_DATA,
  SET_MODAL,
  SET_ROW_ID,
  SET_THEAD,
  SET_VALUES,
} from "./MerosTypes";

function jsonChecker(x) {
  try {
    return JSON.parse(x).data;
  } catch (e) {
    return x;
  }
}
export const getMainTableData = (url, times) => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  let response;

  if (url == "/hisobot") {
    const dates = {
      date1: times[0],
      date2: times[1],
    };
    response = await FetchApi(url, "post", dates);
  } else {
    response = await FetchApi(url, "get");
  }

  if (response && response.status == 200) {
    dispatch({
      type: SET_MAIN_TABLE_DATA,
      payload: response.data.map(d => {
        if (d.hasOwnProperty("data")) {
          return { ...d, data: jsonChecker(d.data) };
        } else {
          return d;
        }
      }),
    });
  }
  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const getAllData = () => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  const data = await FetchApi("/alldata", "get");
  if (data && data.status === 200) {
    dispatch({
      type: GET_ALLDATA,
      payload: data.data,
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};

export const setRowId = data => dispatch => {
  dispatch({
    type: SET_ROW_ID,
    payload: data,
  });
};

export const setClients = data => async dispatch => {
  dispatch({
    type: SET_CLIENTS,
    payload: data,
  });
};

export const setMainTableData = data => dispatch => {
  dispatch({
    type: SET_MAIN_TABLE_DATA,
    payload: data,
  });
};

export const setModal = data => dispatch => {
  dispatch({
    type: SET_MODAL,
    payload: data,
  });
};

export const setValues = data => dispatch => {
  dispatch({
    type: SET_VALUES,
    payload: data,
  });
};

export const setCoords = data => dispatch => {
  dispatch({
    type: SET_COORDS,
    payload: data,
  });
};

export const setExcelData = data => dispatch => {
  dispatch({
    type: SET_EXCEL_DATA,
    payload: data,
  });
};

export const getHtmlHisobot = (url, data) => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });

  let response;
  response = await FetchApi(url, "POST", data);
  if (response && response.status === 200) {
    dispatch({
      type: GET_HTML_HISOBOT,
      payload: response.data,
    });
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};
export const setThead = data => dispatch => {
  dispatch({
    type: SET_THEAD,
    payload: data,
  });
};
export const getLogin = data => async dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: true,
  });
  const response = await FetchApi("/login", "POST", data);
  if (response && response.data.length > 0) {
    window.localStorage.setItem("mаlinaUser", JSON.stringify(response.data[0]));

    window.location.href = "/";
  } else {
    message.error("Login yoki parol xato!");
  }

  dispatch({
    type: SET_LOADING,
    payload: false,
  });
};
