import {Button} from "antd";
import {useState} from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {getMainTableData, setMainTableData, setValues} from "../../Actions/MerosActions";
import FetchApi from "../../api/FetchApi";
import MahsulotBolimForm from "./Forms/MahsulotBolimForm";

const ModalForm = ({values, setValues, clearModal, modal, getMainTableData, path}) => {
	const {location} = useHistory();
	const [loading, setLoading] = useState(false);
	const handleChange = (e, coords) => {
		let obj = {...values};
		if (e) {
			obj[e.target.name] = e.target.value;
		}
		setValues(obj);
	};

	const handleSubmit = () => {
		if (modal.title === "Excel") {
		} else {
			RequestJson(location.pathname, values, getMainTableData);
			clearModal();
		}
	};

	const RequestJson = async (url, data, refreshCallback) => {
		setLoading(true);
		const response = await FetchApi(url, "POST", data);
		if (response && response.status === 200) {
			refreshCallback(url);
			clearModal();
		}

		setLoading(false);
	};
	return (
		<>
			<form onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}>
				<MahsulotBolimForm onChange={handleChange} values={values} modal={modal} path={path}/>
			</form>
			{modal.title !== "Excel" && (
				<div className="form_submit">
					<div style={{display: "flex", justifyContent: "center"}}>
						<Button className={"btn btn-back"} onClick={() => clearModal()}>
							Қайтиш
						</Button>
						<Button loading={loading} className={"btn btn-submit"} onClick={handleSubmit}>
							Сақлаш
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = state => {
	return {
		values: state.meros.values,
		modal: state.meros.modal,
		mainTableData: state.meros.mainTableData,
	};
};

export default connect(mapStateToProps, {setValues, setMainTableData, getMainTableData})(ModalForm);
