import React from "react";
import { RiUserFill } from "react-icons/ri";
import { RiLock2Fill } from "react-icons/ri";
import { getLogin } from "../../Actions/MerosActions";
import { connect } from "react-redux";
import { Input } from "antd";
import "./Login.css";
import brand from "../../icons/brand.svg";
import { Field, Form } from "react-final-form";
import { Button } from "semantic-ui-react";

const loginValues = { login: "", parol: "" };
const Login = ({ getLogin, loading }) => {
  const onSubmit = (values) => {
    getLogin(values);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}>
      <div className="login_area">
        <img src={brand} alt="brand" />
        <p style={{ color: "#311a2f", marginBottom: "2rem" }}>Дорихоналар тармоғи</p>
        <Form
          onSubmit={onSubmit}
          initialValues={loginValues}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fbf2fb",
                    maxWidth: "385px",
                    borderRadius: "20px",
                    paddingLeft: "15px",
                  }}>
                  <RiUserFill style={{ fontSize: "20px", color: "var(--main-color)" }}></RiUserFill>
                  <Field name="login">
                    {(props) => <Input {...props.input} style={{ fontSize: "18px" }} placeholder="Login"></Input>}
                  </Field>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#fbf2fb",
                    maxWidth: "385px",
                    borderRadius: "20px",
                    paddingLeft: "15px",
                    marginTop: "15px",
                  }}>
                  <RiLock2Fill style={{ fontSize: "20px", color: "var(--main-color)" }}></RiLock2Fill>
                  <Field name="parol">
                    {(props) => (
                      <Input.Password
                        {...props.input}
                        style={{ fontSize: "18px" }}
                        placeholder="Parol"></Input.Password>
                    )}
                  </Field>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button loading={loading} type="submit" className="btn btn-submit login_btn">
                    Kirish
                  </Button>
                </div>
              </form>
            </>
          )}></Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.meros.loading,
  };
};

export default connect(mapStateToProps, { getLogin })(Login);
