import { Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";

const RegionForm = ({ modal, onChange, datas, values }) => {
  return (
    <div style={{ width: "520px" }}>
      <Form style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div className="form_group" style={{ width: "100%", marginBottom: "0" }}>
          <label>Вилоятлар</label>
          <Select
            onChange={value => {
              const v = {
                target: {
                  name: "viloyat_id",
                  value: value,
                },
              };

              onChange(v);
            }}
            placeholder="Вилоятни танланг"
            defaultValue={values.viloyat_id}
            style={{ width: 120 }}>
            {datas.oblast.map(d => (
              <Option key={d.Id}>{d.nomi}</Option>
            ))}
          </Select>
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>Номи</label>
          <Input className={"section_name_input"} value={values.nomi} onChange={onChange} name={"nomi"} />
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>№</label>
          <Input className={"section_name_input"} value={values.tartib} onChange={onChange} name={"tartib"} />
        </div>
      </Form>
    </div>
  );
};

export default RegionForm;
