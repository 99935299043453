import { DatePicker, Form, Input, Radio, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { setValues } from "../../../Actions/MerosActions";

const ClientForm = ({ onChange, setValues, datas, values, open }) => {
  useEffect(() => {
    setValues({
      ...values,
      filial_id: JSON.parse(window.localStorage.getItem("mаlinaUser")).id,
    });
  }, [open]);
  return (
    <Form className="client_form">
      <div className="form-group">
        <label>Исм фамилия</label>
        <Input
          className="section_name_input"
          value={values.fio}
          onChange={onChange}
          name={"fio"}
        />
      </div>
      <div className="form-group">
        <label>Телефон рақам</label>
        <PhoneInput
          country={"uz"}
          inputStyle={{
            width: "100%",
          }}
          specialLabel={false}
          disableDropdown={true}
          countryCodeEditable={false}
          value={values.telefon ? values.telefon : "+998"}
          areaCodes={{
            uz: ["+998"],
          }}
          masks={{ uz: "(..) ...-..-.." }}
          prefix="+"
          onChange={(phone) => {
            const e = {
              target: {
                name: "telefon",
                value: "+" + phone,
              },
            };
            onChange(e);
          }}
        />
      </div>
      <div className="form-group">
        <label>Туғулган сана</label>
        <div>
          <DatePicker
            style={{ width: "100%", cursor: "pointer" }}
            onChange={(value, dateString) => {
              const v = {
                target: {
                  name: "birth",
                  value: dateString,
                },
              };
              onChange(v);
            }}
            placeholder={"Кун / Ой / Йил"}
            value={
              values.birth ? moment(values.birth, "YYYY-MM-DD") : undefined
            }
            format={"YYYY-MM-DD"}
          />
        </div>
      </div>
      <div className="form-group">
        <label style={{ display: "block", marginLeft: 0 }}>Жинси</label>
        <Radio.Group onChange={onChange} value={values.jinsi} name={"jinsi"}>
          <Radio value={0}>Эркак</Radio>
          <Radio value={1}>Аёл</Radio>
        </Radio.Group>
      </div>
      <div className="form-group">
        <label>Вилоят</label>
        <Select
          onChange={(value) => {
            const v = {
              target: {
                name: "viloyat_id",
                value: value,
              },
            };
            onChange(v);
          }}
          placeholder="Вилоят"
          defaultValue={values.viloyat_id}
        >
          {datas.oblast.map((ob) => (
            <Option key={ob.Id} value={ob.Id}>
              {ob.nomi}
            </Option>
          ))}
        </Select>
      </div>
      <div className="form-group">
        <label>Шахар / Туман</label>
        <Select
          onChange={(value) => {
            const v = {
              target: {
                name: "shahar_id",
                value: value,
              },
            };
            onChange(v);
          }}
          placeholder="Шахар / Туман"
          value={values.shahar_id}
        >
          {values.viloyat_id
            ? datas.gorod
                .filter((d) => d.viloyat_id == values.viloyat_id)
                .map((sh) => (
                  <Option key={sh.Id} value={sh.Id}>
                    {sh.nomi}
                  </Option>
                ))
            : null}
        </Select>
      </div>
      <div className="form-group">
        <label>МФЙ</label>
        <Select
          onChange={(value) => {
            const v = {
              target: {
                name: "mfy_id",
                value: value,
              },
            };
            onChange(v);
          }}
          placeholder="МФЙ"
          defaultValue={values.mfy_id}
        >
          {values.shahar_id
            ? datas.msg
                .filter((d) => d.shahar_id == values.shahar_id)
                .map((m) => (
                  <Option value={m.Id} key={m.Id}>
                    {m.nomi}
                  </Option>
                ))
            : null}
        </Select>
      </div>
      <div className="form-group">
        <label>Пароль</label>
        <Input.Password
          style={{ paddingLeft: "15px" }}
          onChange={onChange}
          value={values.password}
          name={"password"}
        />
      </div>
      <div className="form-group" style={{ flexGrow: "1" }}>
        <label>Манзил</label>
        <Input
          className="section_name_input"
          value={values.manzil}
          onChange={onChange}
          name={"manzil"}
        />
      </div>
      <div className="form-group">
        <label>Штрих код</label>
        <Input
          maxLength="13"
          className={"section_name_input"}
          value={values.shtrix}
          onChange={onChange}
          name={"shtrix"}
        />
      </div>
      <div className="form-group">
        <label>Телефон рақам 2</label>
        <PhoneInput
          country={"uz"}
          inputStyle={{
            width: "100%",
          }}
          specialLabel={false}
          disableDropdown={true}
          countryCodeEditable={false}
          value={values.telefon2 ? values.telefon2 : "+998"}
          areaCodes={{
            uz: ["+998"],
          }}
          masks={{ uz: "(..) ...-..-.." }}
          prefix="+"
          onChange={(phone) => {
            const e = {
              target: {
                name: "telefon2",
                value: "+" + phone,
              },
            };
            onChange(e);
          }}
        />
      </div>
    </Form>
  );
};
const mapStateToProps = (state) => {
  return {
    open: state.meros.modal.open,
  };
};
export default connect(mapStateToProps, { setValues })(ClientForm);
