import avatar from "../icons/avatar.svg";
import SearchCom from "./SearchCom";
import {GiExitDoor} from "react-icons/gi";

const Header = ({columns, setSearchDataSource}) => {
	const leave = () => {
		window.localStorage.removeItem("mаlinaUser");
		window.location.href = "/login";
	};
	return (
		<div className={"header"} style={{height: "67px"}}>
			<SearchCom columns={columns} setS={setSearchDataSource}/>
			<div style={{display: "flex", alignItems: "center"}}>
				<div className="avatar">
					<div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
						<h4>{JSON.parse(window.localStorage.getItem("mаlinaUser")).nomi}</h4>
						<span>{JSON.parse(window.localStorage.getItem("mаlinaUser")).telefon}</span>
					</div>
					<img style={{display: "block"}} src={avatar} alt={"avatar"}/>
				</div>
				&nbsp; &nbsp;
				<div
					style={{
						fontSize: "24px",
						color: "var(--main-color)",
						cursor: "pointer",
						display: "flex",
					}}
					onClick={leave}>
					<GiExitDoor></GiExitDoor>
				</div>
			</div>
		</div>
	);
};

export default Header;
