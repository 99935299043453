export const SET_MAIN_TABLE_DATA = "SET_MAIN_TABLE_DATA";
export const SET_MODAL = "SET_MODAL";
export const SET_VALUES = "SET_VALUES";
export const SET_ROW_ID = "SET_ROW_ID";
export const SET_LOADING = "SET_LOADING";
export const SET_CLIENTS = "SET_CLIENTS";
export const GET_ALLDATA = "GET_ALLDATA";
export const SET_EXCEL_DATA = "SET_EXCEL_DATA";
export const GET_LOGIN = "GET_LOGIN";
export const SET_COORDS = "SET_COORDS";
export const SET_THEAD = "SET_THEAD";
export const GET_HTML_HISOBOT = "GET_HTML_HISOBOT";
