import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { getHtmlHisobot, setValues } from "../../../Actions/MerosActions";
import { Button, DatePicker, Form, Select } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ru_RU";

const MijozHisoboti = ({
  hisobot,
  modal,
  values,
  setValues,
  getHtmlHisobot,
  allData,
}) => {
  const [dates1, setDates1] = useState(moment(new Date()).format("DD.MM.YYYY"));
  const [dates2, setDates2] = useState(moment(new Date()).format("DD.MM.YYYY"));

  useEffect(() => {
    if (modal && modal.id) {
      getHtmlHisobot("/hisobotMijoz", {
        id: modal.id,
      });
    }
  }, [modal]);

  const customStyles = {
    box: {
      width: "1000px",
      maxHeight: "460px",
      overflow: "scroll",
    },
  };
  const getHisobot = () => {
    getHtmlHisobot("/hisobotMijoz", {
      sana1: dates1 && moment(dates1, "DD.MM.YYYY").format("YYYY-MM-DD"),
      sana2: dates2 && moment(dates2, "DD.MM.YYYY").format("YYYY-MM-DD"),
      id: values?.Id,
    });
  };

  const changeDate1 = (e, d) => {
    setDates1(d);
  };

  const changeDate2 = (e, d) => {
    setDates2(d);
  };

  return (
    <div style={customStyles.box}>
      <Form style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <div
          className="form-group"
          style={{ width: "auto", display: "flex", gap: "0 20px" }}
        >
          <DatePicker
            onChange={changeDate1}
            separator
            defaultValue={moment(new Date(), "DD.MM.YYYY")}
            format={"DD.MM.YYYY"}
            allowClear={false}
            suffixIcon
            placeholder={"Сана 1"}
            locale={locale}
          />
          <DatePicker
            onChange={changeDate2}
            separator
            format={"DD.MM.YYYY"}
            defaultValue={moment(new Date(), "DD.MM.YYYY")}
            allowClear={false}
            suffixIcon
            placeholder={"Сана 2"}
            locale={locale}
          />
        </div>
        <div className="form-group">
          <Button onClick={getHisobot} type="primary">
            Янгилаш
          </Button>
        </div>
        <div>
          {values.fio} ({values.telefon})
        </div>
      </Form>
      {hisobot && hisobot.html ? HtmlParser(hisobot.html) : ""}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    hisobot: state.meros.htmlHisobot,
    modal: state.meros.modal,
    loading: state.meros.loading,
    values: state.meros.values,
    allData: state.meros.allData,
  };
};
export default connect(mapStateToProps, {
  getHtmlHisobot,
  setValues,
})(MijozHisoboti);
