import React, {useEffect, useState} from "react";
import "./App.css";
import {BrowserRouter as Router} from "react-router-dom";
import Login from "./components/Pages/Login";
import Malina from "./components/Malina";

function App() {
	const [user, setUser] = useState();
	useEffect(() => {
		setUser(JSON.parse(window.localStorage.getItem("mаlinaUser")));
	}, []);
	let main = null;

	if (user) {
		main = <Malina/>;
	} else {
		main = <Login/>;
	}
	return (
		<Router>{main}</Router>
	);
}

export default App;
