import { Form, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setValues } from "../../../Actions/MerosActions";

const MsgForm = ({ onChange,tableData,setValues,open,  datas, values }) => {
  useEffect(() => {
    setValues({...values, tartib: tableData.length + 1})
  }, [open])
  return (
    <>
      <Form style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>Вилоят</label>
          <Select
            onChange={value => {
              const v = {
                target: {
                  name: "viloyat_id",
                  value: value,
                },
              };
              onChange(v);
            }}
            placeholder="Вилоят"
            defaultValue={values.viloyat_id}
            style={{ width: 120, marginBottom: "1rem" }}>
            {datas.oblast.map(d => (
              <Option value={d.Id}>{d.nomi}</Option>
            ))}
          </Select>
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>Шахар / Туман</label>
          <Select
            onChange={value => {
              const v = {
                target: {
                  name: "shahar_id",
                  value: value,
                },
              };
              onChange(v);
            }}
            placeholder="Шахар / Туман"
            value={values.shahar_id}
            style={{ width: 120 }}>
            {values.viloyat_id
              ? datas.gorod.filter(d => d.viloyat_id == values.viloyat_id).map(d => <Option  value={d.Id}>{d.nomi}</Option>)
              : null}
          </Select>
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>Номи</label>
          <Input className={"section_name_input"} onChange={onChange} value={values.nomi} name={"nomi"} />
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>№</label>
          <Input className={"section_name_input"} onChange={onChange} value={values.tartib} name={"tartib"} />
        </div>
      </Form>
    </>
  );
};
const mapStateToProps = state => {
  return {
    tableData: state.meros.mainTableData,
    open: state.meros.modal.open
  }
}
export default connect(mapStateToProps, {setValues})(MsgForm);
