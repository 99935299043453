import React, { Component } from "react";
import XLSX from "xlsx";
import { SheetJSFT } from "./types";
import {
  setMainTableData,
  setModal,
  setExcelData,
} from "../../../../Actions/MerosActions";
import { connect } from "react-redux";

const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
    };
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  }

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      // console.log({data: data, cols: make_cols(ws["!ref"])})
      this.setState({data: data, cols: make_cols(ws["!ref"])}, () => {
        // this.props.setMainTableData(
        //   this.state.data.map((d, index) => {
        //     return {
        //       ...d,
        //       // telefon: d.telefon,
        //       id: index + 1,
        //       key: index + 1,
        //     };
        //   })
        // );
        this.props.setExcelData(
          this.state.data.map((d, index) => {
            return {
              ...d,
              // telefon: d.telefon,
              id: index + 1,
              key: index + 1,
            };
          })
        );
      });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }

    setTimeout(() => {
      this.props.setModal({ open: false, title: "" });
    }, 500);
  }

  render() {
    return (
      <div className="excel_reader">
        <label htmlFor="file">Excel файлни юкланг</label>
        <input
          type="file"
          className="form-control"
          id="file"
          accept={SheetJSFT}
          onChange={this.handleChange}
        />
        <input
          type="submit"
          value="Файлни фаоллаштириш"
          onClick={this.handleFile}
        />
      </div>
    );
  }
}
export default connect(null, { setMainTableData, setExcelData, setModal })(
  ExcelReader
);
