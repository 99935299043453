import { Form, Input } from "antd";

const CityForm = ({ onChange, modal, values }) => {
  return (
    <>
      <Form onChange={onChange} style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "520px" }}>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>Номи</label>
          <Input className={"section_name_input"} value={values.nomi} name={"nomi"} />
        </div>
        <div className="form_group" style={{ width: "48%", marginBottom: "0" }}>
          <label>№</label>
          <Input className={"section_name_input"} value={values.tartib} onChange={onChange} name={"tartib"} />
        </div>
      </Form>
    </>
  );
};

export default CityForm;
